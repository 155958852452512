.app-menu {
    list-style: none;
    padding: unset;
    margin: unset;
    height: 71px;
    background: rgba(255, 255, 255, 1);
    font-size: 18px;
    font-weight: 400;
    color: rgba(67, 67, 67, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 40px 3px rgba(103, 103, 103, 0.4);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index:1;
}

.menu-logo {
    width: 260px !important;
    height: 100%;
    background: #FE0000;
    background-image: url(/image/icon/logo.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.menu-space {
    flex: 1;
}

.app-menu>li {
    width: 95px;
    height: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 71px;
    box-sizing: border-box;
}

.app-menu>li>i {
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.app-menu>li>a {
    text-decoration: none;
    height: 100%;
    font-size: 18px;
    color: rgba(67, 67, 67, 1);
    display: block;
}

.app-menu>li:hover>a {
    color: #FE0000 !important;
}

.app-menu>.active {
    border-bottom: 6px solid #FE0000;
}

.app-menu>.active:hover>a {
    color: #FE0000 !important;
}

@media screen and (max-width: 992px) {      
    .app-menu{
        display:flex;
        align-items: center;
        justify-content: space-between;
        box-shadow:1px 1px 3px rgba(0,0,0,.3);
        border-bottom:1px solid #dbdbdb;             
    }  
    .app-menu>li{
        display:none;
    } 
    .app-menu>li.menu-logo,.app-menu>li.phone-nav-icon{
        display:block;
    }
    .app-menu>li.menu-logo{
        background:url(/image/phone/phone-logo.svg);
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
        width: 160px !important;
        height: 64px;
    }
    .app-menu>li.phone-nav-icon{
        background:url(/image/phone/phone-nav-menu.svg) no-repeat;       
        background-position: center; 
        background-size: 60%; 
        width:55px;
        height:40px; 
        margin-right: 15px;    
    }    
    .app-menu.phone{
        width:100%;
        height:100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background:#E60012; 
        flex-flow: wrap;
        justify-content: center;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;            
    } 
    .app-menu.phone>li.menu-logo{
        display:none;
    }
    .app-menu.phone>li.phone-nav-icon{
        display:none;
    }
    .app-menu.phone>li.menu-space{
        display:none;
    }  
    .app-menu.phone>li {
        display:block;
        height:auto;
        width: 100%;   
        line-height:45px;
    }     
    .app-menu.phone>li.phone-logo{
        padding: 10px 20px 0;
    } 
    .app-menu.phone>li.phone-logo div{
        width: 100%;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .app-menu.phone>li.phone-logo a{
        background-image:url(/image/phone/phone-nav-logo.svg);
        background-size:100%;
        width:157px;
        height:54px;
        background-repeat: no-repeat;
        display:inline-block;
    }
    .app-menu.phone>li.phone-logo img{
        padding:10px 10px;
        position: absolute;
        top:10;
        right:0;
        max-width:50px;
    }  
    .app-menu.phone>li>a {    
        height: auto;      
        color: #fff; 
        width: 100%;  
    }
    .app-menu.phone>li:hover>a,.app-menu.phone>.active,.app-menu.phone>.active:hover>a {
        font-weight:600;
        color:#fff !important;
    } 
}
@-webkit-keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}
@-webkit-keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}