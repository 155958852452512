em{
    font-style: normal;
}
.works-banner{
    background-image:url(/image/works-banner.png); 
    background-position: center;
    height:340px;
    padding:0;
}
.updates-content{
    margin:0 -5px;
}
.works-box{
    display: flex;
    flex-flow: column;
}
.works-nav-box{
    background:#fff;
    position: relative;
    border-bottom:2px solid #DEDBDB;
}
.works-content{
    background: rgba(242, 242, 242, 1);
    min-height:400px;
}
.works-nav{
    padding-top:20px;  
}
.works-nav ul {
    display: flex;
    margin: 0 auto;
    width: 50%;
    justify-content: space-between;  
}
.works-nav ul li{
    float:left; 
    line-height:45px;
    font-size:16px; 
    position: relative;  
}
.works-nav ul li i{
    background:url('/image/icon/down-arrow.svg') no-repeat;
    background-size:100%;
    width:12px;
    height:7px;
    display:inline-block;
    margin-left:5px;
    transition: transform .3s linear;
    -webkit-transition: transform .3s linear;
    
}
.works-nav ul li.active i{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    background:url('/image/icon/down-arrow.svg') no-repeat;
}

.works-nav ul li.active div{
    border-bottom:2px solid #FE0000;
}
.works-list{
    margin:0 -10px;
    overflow: hidden;
}
.works-list li{
    width:25%;
    *width:168px;
    padding-left:10px;
    padding-right:10px;
    float:left;
    position:relative;
    margin-bottom:20px;
    cursor:pointer;
}
.works-list li:last-child{
    margin-right:0;
}
.works-list .works-list-item{
    overflow:hidden;
    background: #f5f5f5
}
.works-pic{
    *width:168px;
    margin:0 auto;
    text-align:center;
    height:130px;
    overflow:hidden;
    position:relative;
    border-radius: 4px 4px 0 0;
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
}
.works-pic img{
    max-width:100%;
    height:auto;    
}
.works-list-summary{
    overflow:hidden;
    padding-bottom:5px;
    text-align:left; 
    background:#fff;  
}
.works-list-summary h4{
    font-size:16px;
    color:#4a4a4a;
    font-weight:600;
    padding:10px 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.works-list-summary p{
    color:#9B9B9B;
    line-height:25px;
    padding:0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.works-list .works-list-item:hover{
    box-shadow:0 0 7px rgba(0,0,0,.3);
}
.works-opertion{   
    padding:10px;
}
.works-opertion span{
    display:inline-block;
    color:#9B9B9B;
    margin-right:10px;
    cursor: default;
}
.works-opertion span img{
    vertical-align: baseline;
    margin-right:5px;
}
.works-bottom{
    border-top:1px solid #F2F2F2;
    display:flex;
    padding:10px;
    justify-content: space-between;
}
.works-name{
    color: #4a4a4a;
}
.works-time{
    color:#9B9B9B;    
    text-align: right;
}
.all-menu{
    position: absolute;
    top:101%;
    left:0;
    width:100%;
    z-index:99;
    background:#fff;
    box-shadow:1px 1px 3px rgba(0,0,0,.3);     
}
.all-menu.show{
    -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        transition: .5s ease-in-out; 
}
.all-menu ul{
    display:flex;
    padding:20px 0;
    flex-flow: wrap;
    justify-content: space-around;
}
.all-menu ul li{
    float:left;    
    font-size:18px;
    color:#4A4A4A;
    text-align: center;
    cursor: pointer;
}
.all-menu ul li.active,.all-menu ul li:hover{
    color:#FE0000;    
}
.all-menu.area-menu ul li{
    width:16.666%;    
    flex: none;
    margin:10px 0;
}

/* 分页 */
.pagebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:14px;
    color:#4a4a4a;
    width:100%;
    margin-top:15px;
}
.pageleft span,.pageright span{
    width:80px;
    height:45px;
    line-height:45px;
    display:inline-block;  
    background:#fff;    
    font-size:14px; 
    cursor:pointer; 
}
.pageleft span{
    margin-right:10px;
}
.pageright span{
    margin-left:10px;
}
/* .pagebox span i{
    width:9px;
    height:12px;
    display:inline-block;
    background-repeat:no-repeat;
    background-position: center;    
}
.pageprev i{
    background-image:url(/image/icon/prev-icon.svg);   
    margin-right:3px;
}
.pagenext i{
    background-image:url(/image/icon/next-icon.svg);
    margin-left:3px;
} */
.pageleft span:hover,.pageright span:hover,.pageleft span.active,.pageright span.active{
    background:#FE0000;
    color:#fff;
}
.pageNumInput{   
    width:60px; 
    height:45x;
    line-height:45px;
    text-align:center;
    border:none;
    background:#fff;
    margin-left:3px;
    margin-right:3px;
    vertical-align:middle;
}

@media screen and (max-width: 992px) {
    .works-banner{
        background-image:url(/image/phone/phone-works-banner.png);    
        height:auto; 
        padding-top: 16%;
        padding-bottom: 8%;   
    } 
    .updates-content ul li{
        width:50%;
    }  
    .works-list li{
        width:50%;
    }    
    .all-menu ul li{
        font-size:14px;
    } 
    .works-content{
        padding:20px 0 50px;
        min-height:auto;
    }   
}
@media screen and (min-width: 768px) and (max-width:992px){
    .works-list li{
        width:33.333%;
    }
}
@media screen and (max-width:767px){
    .works-nav ul li{
        font-size:14px;
    }
    .all-menu ul{
        justify-content: inherit;
    }    
    .all-menu ul{
        padding:10px 0;
    }
    .all-menu ul li{
        width:33.333%;
        margin:10px 0;
    }
    .all-menu.area-menu ul li{
        width:33.333%;
        margin:10px 0;
    }
    .all-menu.area-menu ul{
        overflow-y: auto;
        overflow-x: hidden;
        margin:20px 0;
        max-height: 160px;
    }
    .pageleft span.pagefirst,.pageright span.pagelast{
       display:none;
    }
    .pagemid{
        display:none;
    }
    
}
@media screen and (max-width:400px){
    .works-nav ul{
        width: 80%;
    } 
    .works-nav ul li{
        font-size:14px;
    }
}
@media screen and (max-width:320px){
    .all-menu ul li{
       width:50%;  
    }
}