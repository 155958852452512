
.news-banner{
    background-image:url(/image/news-banner.png);  
}

.News-right-box.home-news-left{
    background: none;
    width:100%;
    padding:0;
    margin-bottom:25px;
    height:auto;
}
.News-right-box.home-news-left li{
    background:#fff;
    padding:0;
    margin: 0px 0 10px;
}
.News-right-box.home-news-left .news-left-img{
    margin:0;
    background-size: cover;
    max-height: inherit;
    background-repeat: no-repeat;
    width:250px;
    background-position: inherit;
}
.News-right-box.home-news-left .news-left-content p {
    margin:10px 0;
}
.News-right-box .news-left-content{
    padding:25px 20px;
    flex:1;
}
.News-right-box.home-news-left li:hover{
    box-shadow: 0 0 7px rgba(0,0,0,.2);
}
.news-footer{
    display:flex;
    justify-content: space-between;
}
@media screen and (max-width: 992px) {
    .news-banner{
        background-image:url(/image/phone/phone-news-banner.png);
    }
    .about-nav,.about-right{
        width:100%;
    } 
    .News-right-box .news-left-content{
        padding:15px 20px;
    } 
    .news-footer{
        padding-top:10px;
    }  
}
@media screen and (max-width: 767px) {
    .News-right-box.home-news-left .news-left-img{
        width:100px;
    }    
}