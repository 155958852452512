.awards-banner{
    background-image: url(/image/awards-banner.png);
    background-position: center;
}
.awards .about-right-box{
    overflow:hidden
}
.awards-only{ 
    width:100%;  
    padding:0 5px;
}
.awards-pic{
    overflow:hidden;
    border-bottom:1px solid #DEDBDB;
    margin-bottom: 20px;
}
.awards-pic h3{
    margin:0;
}
.awards-pic-cover{
    padding:20px 0;
    position: relative;
}
.totalnum{
    position: absolute;
    bottom:45px;
    right:20px;
    padding:4px 10px;
    background:rgba(5, 5, 5, 0.8);
    color:#fff;
    font-size:14px;
}
.awards-pic-left{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-pic-right{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-two .awards-pic-left{
    width:50%;
    float:left;
    padding:0 5px;   
}
.awards-two .awards-pic-right{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-pic .works-details-top-left{
    width:100%;
    padding:0 5px;
    border:none;
    margin: 20px 0;
}
.works-details-top-left h2{
    font-weight:500;
}
.awards-pic .works-opertion span{
    margin-right:0;
    margin-left:10px;
}
.awards-pic .works-top-sorts span:first-child{
    font-size:18px;
    border:none;
    padding-right:0;
}
.awards-pic .red-line{
    width:40px;
}
@media screen and (max-width: 992px) {
    .awards-banner{
        background-image: url(/image/phone/phone-awards-banner.png);
    }
    .awards-pic-cover{
        padding:20px 0 0;
    }
    .totalnum{
        bottom:25px;
    }
}
