.noData{
    padding: 200px 0;
    margin: 0 auto;
    display: table;
    text-align: center;
    max-width:240px;  
    /* position: fixed;
    top: 50%;
    left: 50%;
    height: auto;
    z-index: 98;
    width: auto;
    min-width: 240px;   
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%); */    
}
.noData p{
    font-sizE: 18px;
    color: #4a4a4a;
    margin-top: 28px;
    letter-spacing: 1px;    
}
.review-box .about-box{
    min-height:700px;
}
@media screen and (max-width: 992px) {
    .noData{
        max-width:160px;
        padding:100px 0;
    }
    .noData p{
        font-size:16px;
    }
    .review-box .about-box{
        min-height:auto;
    }
}