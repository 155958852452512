.common-banner{
    position: relative;
    width:100%;
    background-image:url(/image/about-banner.png);
    background-repeat: no-repeat; 
    display: flex;
    align-items: center;
    /* padding-bottom: 8%;
    padding-top: 10%;  */
    height:340px;
}
.banner-title-line{
    width: 80px;
    height:4px;
    background-color: #fff;
}
.common-banner h2{
    font-size:34px;
    color:#fff; 
    letter-spacing: 2px;  
    padding:10px 0 0; 
}
.about-box{
    display:flex;      
    background: rgba(242, 242, 242, 1);      
}
.about-content{
    margin:0 -5px;
    min-height:400px;
}
.about-nav{    
    width: 240px;
    float: left;
    padding: 0 5px;
}
.about-nav ul li{
  background:#fff;  
  color:#4A4A4A;
  text-align:center;
  margin-bottom:10px; 
  height:60px;
  line-height:60px; 
  cursor:pointer;
  font-size:18px;
}
.about-nav ul li.active{
    background:#FE0000;
    color:#fff;
}
.about-right{    
    width: calc(100% - 240px);   
    padding: 0 5px;
    float: left;
}
.about-right-box{
    background: #fff;
    padding: 40px 20px; 
}
.about-right-box h2{
    font-size:24px;
    margin: 0;
}
.about-describe span{
    display:block;
}
.about-summary{
    padding:0 20px;
}
.about-summary h3{    
    font-weight:600;
}
.judges-name{
    padding:20px 0 15px;  
    color:#000;
}
.about-black{
    color:#000;    
    font-weight:600;
}
.about-summary ul li {
    border-bottom:1px solid #efefef;
    padding:40px 0 20px;
}
.about-summary ul li p{
    margin:0;
}
.about-summary ul li:last-child{
    border:none;
}
.about-right-box p{
    text-align: left;
    font-size:14px;
    color:#434343;
    line-height:25px;
    margin:10px 0;
}
.red-line{
    width:80px;
    height:4px;
    background: #FE0000;
    display:inline-block;
}
.ant-tabs {
    display:none;
}
.ant-tabs-bar{
    border:none;
}
.about-right-sort{
    max-height:700px;
    overflow-y: auto;
}
.about-right-sort::-webkit-scrollbar { display: none; }
@media screen and (max-width: 992px) {
    .common-banner{
        background-image:url(/image/phone/phone-about-banner.png);  
        background-size: 100%;
        padding-top: 16%;
        padding-bottom: 8%;
        height:auto;
    }
    .about-content{
        min-height:auto;
    }
    .about-nav,.about-right{
        width:100%;
    }
    .about-box{
        min-height:auto;
        padding: 20px 0 50px;        
    }
    .about-nav{
        display:none;
    }
    .ant-tabs {
        display:block;       
    }
    .ant-tabs-nav .ant-tabs-tab{
        font-size:16px;
    }
    .ant-tabs-nav .ant-tabs-tab-active{
        color:#FE0000;
    }
    .ant-tabs-nav .ant-tabs-tab:hover{
        color:#FE0000;
    }
    .ant-tabs-ink-bar{     
        background-color:#FE0000
    }
    .common-banner h2{
        font-size:24px;
    }
    .about-summary{
        padding:0;
    }
    
}