.home-footer {
    height: 140px;
    background: rgba(67, 67, 67, 1);
    display: flex;
    align-items: center;  
    /* position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0; */
}
.home-footer-box{
    width:100%;
    font-size:14px;
    color:#AAAAAA;
}
.home-footer-left{
    width:20%;
    float:left;
    padding:0 5px;
    margin-top:20px;
    text-align: center;
}
.home-footer-middle{
    width:55%;
    float:left;
    padding:0 5px;
    text-align:center;   
    margin-top:30px;
}
.home-footer-right{
    width:25%;
    float:left;
    padding:0 5px; 
    margin-top:10px;
}
.home-footer-right ul{
    display: flex;
    margin: 0 -5px;
    justify-content: flex-end;    
}
.home-footer-right ul li{
    float: left;
    padding: 0 5px;
    text-align: center;
}
.home-footer-right ul li img{
    max-width:100px;
}
.foot-btn{
    width:100%;
    height:40px;
    line-height:40px;
    color:#fff;
    background: #FF0000;
    text-align:center;
    display:block;
    margin-top:10px;
    cursor:pointer;
}
.foot-btn:hover{
    color:#fff;
}
@media screen and (max-width:992px){
    .home-footer{
        height:auto;
        padding:20px 0;
        /* position: inherit; */
    }
    .home-footer-left,.home-footer-middle,.home-footer-right{
        width:100%;
    }
    .home-footer-right{
        margin-top:30px;
    }
    .foot-btn{
        width: 140px;
        display: block;     
        margin: 20px auto 0;
    }
    .home-footer-middle{
        margin-top:30px;
    }
    .home-footer-right ul{
        justify-content: space-around;
    }
    /* .home-footer-right ul li{
        padding:0 15px;
    } */
}