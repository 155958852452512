*{
    padding:0;
    margin:0;
}
p,h4,ul,li{
    padding:0;
    margin:0; 
}
ul,li{
    list-style-type: none;
}
img{
    max-width:100%;
    height:auto;
}
.clearfix:after {
    content: '';
    display: block;
    clear: both;
}
.layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;
    padding:50px 0;
}
.prow{
    margin:0 -10px;
}
.container{
    width:1200px;  
    margin:0 auto;   
    padding-left:10px;
    padding-right:10px; 
}
.more{
    width:156px;
    height:44px;
    line-height:44px;   
    text-align:center;    
    margin:40px auto 0;
    color:#4A4A4A;
    border:1px solid #4A4A4A;
}
.more a{
    color:#4A4A4A;
}
.more-red a{
    color:#FF0000;
}
.more-red{
    color:#FF0000;
    border:1px solid #FF0000;
}
.more-grey{
    color:#4A4A4A;
    border:1px solid #4A4A4A;
}
.more-grey a{
    color:#4A4A4A;
}
.more-icon{
    width:23px;
    height:6px;
    display:inline-block;
    vertical-align:3px;
    margin-left:5px;
    background:url(/image/icon/more-grey.png) no-repeat;
}
.more-red-icon{
    background:url(/image/icon/more-red.png) no-repeat;   
}
.more-grey-icon{
    background:url(/image/icon/more-grey.png) no-repeat; 
}
.more:hover{
    color:#FF0000;
    border:1px solid #FF0000;
}
.more:hover a{
    color:#FF0000;
}
.more:hover .more-icon{
    background:url(/image/icon/more-red.png) no-repeat; 
}
.homepage {
    color: rgba(67, 67, 67, 1); 
}

.home-banner {
    background-color: antiquewhite;
    position: relative;
    width:100%;
    background-image:url(/image/index-banner.png);
    background-repeat: no-repeat;
    background-size:cover; 
    display: flex;
    align-items: center;
}
.banner-title > div{
    margin:20px 0;    
}

.banner-title{
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
}
.banner-logo{
    position: absolute;
    top: 0;
    left: 10px;  
}
.banner-logo span{
    margin:0 10px;      
}
.banner-logo span:first-child{
    /* border-right:1px solid #8c8989; */
    padding-right:20px;
    display:inline-block;
}
.banner-title h2{
    font-size:50px;
    color:#fff;
    letter-spacing: 10px;
}
.banner-btn{
   width:156px;
   height:44px;
   line-height:44px;
   text-align:center;
   font-size:14px;
   color:#fff;
   background:#FF0000; 
}
.banner-btn:hover{
    cursor: pointer;
}
.home-about {   
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:50px;
    padding-bottom:50px;
    background:#fff;
}
.title-tip{
    font-size:14px;
    color:#FF0000;
    margin:0;
}
.title {
    font-size: 34px;
    color:#434343;
}
.about-detail{
   margin-top:20px;
   line-height:25px;
   color:#434343;
   font-size:16px;  
   letter-spacing:1px; 
}
.about-detail p{
    line-height:30px;
}

.about-video{
    background:url(/image/about-bg.png);
    background-size:contain;
    background-repeat: no-repeat; 
    width: 100%;
    padding:20% 0;     
    background-position: center; 
    margin-top:40px; 
    display: flex;
    justify-content: center 
}
.about-btn{
    width:100%;
    margin-top:20px;
}
.about-btn ul{
    margin: 0 -5px;
    overflow:hidden;
}
.about-btn ul li{
    float:left;
    width:20%;   
    text-align:center;
    font-size:18px;
    color:#434343;
    height:75px;
    line-height:75px;
    padding-left: 5px;
    padding-right: 5px; 
    position: relative;
    margin-bottom: 20px
}
.about-btn ul li div{
    border:1px solid #BFBFBF; 
    cursor:pointer;
}
.about-btn ul li.active div{
    border:1px solid #FF0000;
    background:#FF0000;
    color:#fff;
}
.home-news {   
    background: rgba(242, 242, 242, 1);    
}
.home-news-switch>span{
    font-size:24px;   
    margin: 20px;
    padding: 10px 0;
    display: inline-block
}
.home-news-switch>span.active{
    color:#FF0000;
    border-bottom:2px solid #FF0000;
}
.home-news-switch>span:hover {
    cursor: pointer;
}
.home-news-content{
    display:flex;
    margin-top:20px;
}
.home-news-left{
    /* width:calc(50% - 40px); */
    width:50%;
    float:left;  
    text-align:left;
    padding: 10px 20px 10px;
    background:#fff;
    height:500px;
}
.home-news-left li{
    display:flex;
    color:#626262;
    border-bottom: 1px solid #efefef; 
    padding: 5px 0 30px;
    margin: 20px 0 20px;   
}
.home-news-left li:last-child{
    border:none;
    margin: 20px 0 0;
}
.news-left-img{
    width:100px;
    max-height:100px;   
    margin-right:20px;
    margin-top:6px;
    background-size: cover;
    max-height: inherit;
    background-repeat: no-repeat   
}
.news-left-content{
    flex:1;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
}
.news-left-content h4{
    font-size: 18px;
    color: #434343;
    font-weight: 600;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.home-news-left li:hover .news-left-content h4{
    color:#FF0000;
}
.news-left-content h4.active{
    color: #FF0000;
}
.news-left-content p{   
   font-size:14px;
   line-height:20px;
   margin:5px 0;
   overflow:hidden;
   text-overflow: ellipsis;
   display:-webkit-box;
   -webkit-line-clamp:2;
   -webkit-box-orient: vertical;      
}
.news-left-content span{
    font-size:12px;  
}
.home-news-right{
    width:50%;
}
.ant-carousel .slick-slide {
    text-align: center;    
    background: #fff;
    overflow: hidden;
    height:500px;
}  
.ant-carousel .slick-slide h3 {
    color: #fff;
}
.carouse-img{
    background-size:cover;
    background-repeat: no-repeat;
    width:100%;
    height:500px;
    position: relative;    
}
.carouse-img-bottom{
    position: absolute;
    width:100%;    
    right:0;
    bottom:0;
    height:80px;
    z-index:99; 
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
    font-size:16px;
    color:#fff;
    text-align:left;
    padding:5px 20px;
}
.carouse-img-bottom p{
    margin:0;
    line-height:25px;
}
.carouse-img-bottom span{
    font-size:14px;
}
.home-division-entrance {
    background:#fff;   
}
.home-division-entrance-box{    
    display:flex; 
    margin:20px -5px 0 -5px;
}
.home-division-entrance-left{
    width:25%;   
    padding:0 5px;
}
.home-division-entrance-right{
    width:75%;  
    padding:0 5px;
}
.home-division-entrance-right ul{
    margin:0 -5px;
}
.home-division-entrance-right li{
    width:16.666%;
    padding:0 5px;
    float:left;
    margin-bottom:10px;
    font-size:18px;
}
.home-division-entrance-right li a{
    border:1px solid #D2D2D2;
    display:block;
    border-radius:5px;
    height:64px;
    line-height:64px;
    color:#535353;
    
}
.home-division-entrance-right li:hover a,.home-division-entrance-right li.active a{
    background:#FF0000;
    color:#fff;
}
.home-division-entrance-right li.default a{
    background:#AAAAAA;
    color:#fff;
    cursor: default;
}
.home-division-entrance-left-box{  
    background:url(/image/star-bg.png) no-repeat;    
    background-size:cover;
    padding: 24% 0;
}
.home-division-area{
    font-size:50px;
    color:#fff;
    padding:10px 0;
}
.home-awards {    
    background: linear-gradient(-45deg, rgba(253, 119, 89, 1) 0%, rgba(234, 50, 63, 1) 100%);
}
.home-awards .title-tip,.home-awards .title{
    color:#fff;
}
.home-awards-box ul{
    margin:0 -5px;
}
.home-awards-box li{
    width:20%;
    padding:0 5px;
    float:left;
    margin-bottom:10px;
}
.home-awards-box li div{
    width:100%;
    background:url(/image/prize.png) no-repeat;
    background-size:100%;
    padding-bottom: 106%;
    position: relative;
}
.home-awards-box li div p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-75%);
    font-size: 14px;
    width: 100%;
    color:#FF0000;
}
.home-time {   
    background: rgba(255, 255, 255, 1);
}
.home-time-box{
    margin-top:20px;
    padding:50px 5px;
}
.home-time-box ul{
    width:100%;
}
.home-time-box ul li{
    width: 16.666%;
    display: inline-block;
    position: relative;
    font-size:16px;
    color:#434343;
    font-weight: 600;
    height: 100px;
    background:url(/image/icon/time-line.svg) repeat-x 0 51px;
}
.home-time-box ul li p{
    width:100%;
    padding:10px 0;
    position: absolute;
    left: -34%;
}
.home-time-box ul li p.home-times{    
    top: -25px;
}
.home-time-box ul li p.home-time-title{   
    bottom: -25px;
}
.home-time-box ul li span{
    width:60px;
    height:60px;
    background-size:100%;      
    display:inline-block;
    position: absolute; 
    left:0; 
    top: 20px;    
}
.phone-time-img{
    display:none;
}
.home-judges {   
    background: rgba(67, 67, 67, 1);
}
.home-judges .title-tip,.home-judges .title{
   color:#fff;
}
.home-judges-box{
    margin-top:40px;
}
.home-judges-box ul{
    margin:0 -5px;
    display:flex;
    justify-content:space-around
}
.home-judges-box ul li{
    width:20%;
    float:left;
    padding:0 5px;
    color:#fff;
}
.home-judges-box ul li img{
    max-width:120px;
}
.home-judges-box ul li h4{
    font-size:18px;
    color:#fff;
    padding:20px 0;
}
.home-judges-describe p{
    line-height:25px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.home-supports {  
    background: #f2f2f2;
}
.home-supports-box{
    margin:20px 0;
}
.home-supports-box ul{ 
    margin:0 -5px;
    /* display: flex;
    justify-content: center; */
}
.home-supports-box li{
    width:20%;
    float:left;
    padding:0 5px;
    margin-bottom:10px;
}
.home-supports-box li img{
    border:1px solid #e0dada;
}

.back{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
@media screen and (max-width: 1200px) {
    .container {
       width:100%;
    }
}
@media screen and (min-width: 768px) and (max-width:992px){   
    .title{
        font-size:24px;
    }
    .more{
        margin:20px auto 0;
    }
    .banner-title > div{
        margin:10px 0;
    }
    .banner-logo{
        position:inherit;
        margin: 10px 0 20px !important;
    }
    .home-news-content{
        flex-flow: column-reverse
    }       
    .home-news-left{
        width:100%; 
        background:none;
        padding:0; 
        height:auto;        
    }
    .home-news-right{
        width:100%;
    } 
    .home-news-left li{
        background:#fff;
        padding:0;
        margin: 10px 0;
    }     
    .news-left-img{
        margin-top:0;
    } 
    .news-left-content{
        flex:1;
        justify-content: space-around;
    }  
    .news-left-content p{
        display:none;
    } 
    .news-left-content h4{
        padding: 10px 10px 0 0;
        -webkit-line-clamp: 2;
    }  
    .home-division-entrance-box{
        flex-flow: column-reverse
    }
    .home-division-entrance-left{
        width:100%;
    }
    .home-division-entrance-right{
        width:100%;
    }
    .home-division-entrance-right li{
        width:33.333%;
        font-size:16px;
    }
    .home-awards-box li{
        width:33.333%;
    } 
    .home-judges-box ul li{
        width:50%;
        margin-bottom: 20px;        
    }
    .home-judges-describe p{
        letter-spacing: 0;
    }
    .home-supports-box li{
        width:25%;
    }
    .home-time-box ul{
        display:none;
    }
    .phone-time-img{
        display: block;
        margin:0 auto;
    }
    .home-awards-box li div p{
        font-size:16px;
    } 
    .ant-carousel .slick-slide{
        height:auto;
    }
    .home-division-entrance-left-box{
        padding:22px 0;
        background-size: 100%;
    }
    .carouse-img{
        height:auto;
        padding-bottom:67%;
    }
    .home-news-left li:last-child{
        margin:0;
    }
    .news-left-content span{
        padding-bottom:10px;
    }   
}
@media screen and (max-width: 767px) {    
    .title{
        font-size:24px;
    }
    .more{
        margin:20px auto 0;
    }
    .banner-title > div{
        margin:10px 0;
    }
    .banner-logo{
        position:inherit;
        margin: 10px 0 20px !important;
    }
    .home-news-content{
        flex-flow: column-reverse
    }       
    .home-news-left{
        width:100%; 
        background:none;
        padding:0; 
        height:auto;  
    }
    .home-news-right{
        width:100%;
    } 
    .home-news-left li{
        background:#fff;
        padding:0;
        margin: 10px 0;
    }     
    .news-left-img{
        margin-top:0;
    } 
    .news-left-content{
        flex:1;
        justify-content: space-around;
    }  
    .news-left-content p{
        display:none;
    } 
    .news-left-content h4{
        padding: 10px 10px 0 0;
        -webkit-line-clamp: 2;
    }  
    .home-division-entrance-box{
        flex-flow: column-reverse
    }
    .home-division-entrance-left{
        width:100%;
    }
    .home-division-entrance-right{
        width:100%;
    }
    .home-division-entrance-right li{
        width:33.333%;
        font-size:16px;
    }
    .home-awards-box li{
        width:50%;
    } 
    .home-judges-box ul li{
        width:50%;
        margin-bottom: 20px;        
    }
    .home-judges-describe p{
        letter-spacing: 0;
    }
    .home-supports-box li{
        width:50%;
    }     
    .home-time-box ul{
        display:none;
    }
    .phone-time-img{
        display: block;
        margin:0 auto;
    }
    .home-awards-box li div p{
        font-size:16px;
    } 
    .ant-carousel .slick-slide{
        height:auto;
    }
    .home-division-entrance-left-box{
        padding:22px 0;
        background-size: 100%;
    }     
    .carouse-img{
        height:auto;
        padding-bottom:67%;
    }
    .home-news-left li:last-child{
        margin:0;
    }
    .news-left-content span{
        padding-bottom:10px;
    }
    .news-left-content h4{
        font-size:16px;
    }
}
@media screen and (max-width: 540px) {
    .banner-title h2 {
       font-size:38px;
    }
}