.awards-details .about-right{
   width:100%;
}
.awards-only ul li{
    margin:5px 0;
    
}
.awards-pic-describe p{
   line-height:25px;
   font-size:14px;
   margin: 10px 0;
   color: #4a4a4a;
}
.awards-details .awards-pic{
    border:none;
}
.awards-details .awards-pic .works-details-top-left{
    margin:20px 0 10px;
}