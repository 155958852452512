.news-right-details{
    background:#fff;
    padding: 20px;
}
.news-right-details h2{
    font-size:24px;
    font-weight:600;
    color:#4a4a4a;
    letter-spacing: 1px;
}
.news-details-tip span{
    font-size:16px;
    padding: 0 20px 0;
}
.news-details-tip span:first-child{
    border-right:1px solid #4a4a4a;
}
.about-nav ul li a{
    color:#fff;
}
.news-details-p{
    padding:20px 0;
}
.news-details-p p{
    text-align:left;
    line-height:25px;
    color:#4a4a4a;
}
.news-details-p img{
    width:100% !important;
}