body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
    text-align: center;
    box-sizing: border-box;
}

*:not(input) {
    /* user-select: none; */
    outline: none;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.app-content {
    position: absolute;
    top: 71px;
    right: 0;
    lefT: 0;
    bottom: 0;
    /* overflow-y: auto;   */
    background:#f2f2f2;  
    width: 100%;      
}
.loading-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.scrollbar::-webkit-scrollbar-track {
    width: 4px;
    border-radius: 4px;
    background-color: #DEDEDE;
}

.scrollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #DEDEDE;
}

.scrollbar::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 4px;
    background: #999;
}

.scrollbar::-webkit-scrollbar-corner {
    width: 4px;
    background-color: #999;
}
/* 触摸滚动 */
* {
    -webkit-overflow-scrolling: touch;
}
.ant-back-top {
    bottom: 200px;
  }
.ant-back-top-inner {
    height: 40px;
    width: 40px; 
    line-height: 34px; 
    border-radius: 4px;
    background-color: #fff;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,.5);
}
@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.app-menu {
    list-style: none;
    padding: unset;
    margin: unset;
    height: 71px;
    background: rgba(255, 255, 255, 1);
    font-size: 18px;
    font-weight: 400;
    color: rgba(67, 67, 67, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 40px 3px rgba(103, 103, 103, 0.4);
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index:1;
}

.menu-logo {
    width: 260px !important;
    height: 100%;
    background: #FE0000;
    background-image: url(/image/icon/logo.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.menu-space {
    flex: 1 1;
}

.app-menu>li {
    width: 95px;
    height: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 71px;
    box-sizing: border-box;
}

.app-menu>li>i {
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.app-menu>li>a {
    text-decoration: none;
    height: 100%;
    font-size: 18px;
    color: rgba(67, 67, 67, 1);
    display: block;
}

.app-menu>li:hover>a {
    color: #FE0000 !important;
}

.app-menu>.active {
    border-bottom: 6px solid #FE0000;
}

.app-menu>.active:hover>a {
    color: #FE0000 !important;
}

@media screen and (max-width: 992px) {      
    .app-menu{
        display:flex;
        align-items: center;
        justify-content: space-between;
        box-shadow:1px 1px 3px rgba(0,0,0,.3);
        border-bottom:1px solid #dbdbdb;             
    }  
    .app-menu>li{
        display:none;
    } 
    .app-menu>li.menu-logo,.app-menu>li.phone-nav-icon{
        display:block;
    }
    .app-menu>li.menu-logo{
        background:url(/image/phone/phone-logo.svg);
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
        width: 160px !important;
        height: 64px;
    }
    .app-menu>li.phone-nav-icon{
        background:url(/image/phone/phone-nav-menu.svg) no-repeat;       
        background-position: center; 
        background-size: 60%; 
        width:55px;
        height:40px; 
        margin-right: 15px;    
    }    
    .app-menu.phone{
        width:100%;
        height:100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background:#E60012; 
        flex-flow: wrap;
        justify-content: center;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;            
    } 
    .app-menu.phone>li.menu-logo{
        display:none;
    }
    .app-menu.phone>li.phone-nav-icon{
        display:none;
    }
    .app-menu.phone>li.menu-space{
        display:none;
    }  
    .app-menu.phone>li {
        display:block;
        height:auto;
        width: 100%;   
        line-height:45px;
    }     
    .app-menu.phone>li.phone-logo{
        padding: 10px 20px 0;
    } 
    .app-menu.phone>li.phone-logo div{
        width: 100%;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .app-menu.phone>li.phone-logo a{
        background-image:url(/image/phone/phone-nav-logo.svg);
        background-size:100%;
        width:157px;
        height:54px;
        background-repeat: no-repeat;
        display:inline-block;
    }
    .app-menu.phone>li.phone-logo img{
        padding:10px 10px;
        position: absolute;
        top:10;
        right:0;
        max-width:50px;
    }  
    .app-menu.phone>li>a {    
        height: auto;      
        color: #fff; 
        width: 100%;  
    }
    .app-menu.phone>li:hover>a,.app-menu.phone>.active,.app-menu.phone>.active:hover>a {
        font-weight:600;
        color:#fff !important;
    } 
}
@-webkit-keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}
@-webkit-keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}
.home-footer {
    height: 140px;
    background: rgba(67, 67, 67, 1);
    display: flex;
    align-items: center;  
    /* position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0; */
}
.home-footer-box{
    width:100%;
    font-size:14px;
    color:#AAAAAA;
}
.home-footer-left{
    width:20%;
    float:left;
    padding:0 5px;
    margin-top:20px;
    text-align: center;
}
.home-footer-middle{
    width:55%;
    float:left;
    padding:0 5px;
    text-align:center;   
    margin-top:30px;
}
.home-footer-right{
    width:25%;
    float:left;
    padding:0 5px; 
    margin-top:10px;
}
.home-footer-right ul{
    display: flex;
    margin: 0 -5px;
    justify-content: flex-end;    
}
.home-footer-right ul li{
    float: left;
    padding: 0 5px;
    text-align: center;
}
.home-footer-right ul li img{
    max-width:100px;
}
.foot-btn{
    width:100%;
    height:40px;
    line-height:40px;
    color:#fff;
    background: #FF0000;
    text-align:center;
    display:block;
    margin-top:10px;
    cursor:pointer;
}
.foot-btn:hover{
    color:#fff;
}
@media screen and (max-width:992px){
    .home-footer{
        height:auto;
        padding:20px 0;
        /* position: inherit; */
    }
    .home-footer-left,.home-footer-middle,.home-footer-right{
        width:100%;
    }
    .home-footer-right{
        margin-top:30px;
    }
    .foot-btn{
        width: 140px;
        display: block;     
        margin: 20px auto 0;
    }
    .home-footer-middle{
        margin-top:30px;
    }
    .home-footer-right ul{
        justify-content: space-around;
    }
    /* .home-footer-right ul li{
        padding:0 15px;
    } */
}
*{
    padding:0;
    margin:0;
}
p,h4,ul,li{
    padding:0;
    margin:0; 
}
ul,li{
    list-style-type: none;
}
img{
    max-width:100%;
    height:auto;
}
.clearfix:after {
    content: '';
    display: block;
    clear: both;
}
.layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;
    padding:50px 0;
}
.prow{
    margin:0 -10px;
}
.container{
    width:1200px;  
    margin:0 auto;   
    padding-left:10px;
    padding-right:10px; 
}
.more{
    width:156px;
    height:44px;
    line-height:44px;   
    text-align:center;    
    margin:40px auto 0;
    color:#4A4A4A;
    border:1px solid #4A4A4A;
}
.more a{
    color:#4A4A4A;
}
.more-red a{
    color:#FF0000;
}
.more-red{
    color:#FF0000;
    border:1px solid #FF0000;
}
.more-grey{
    color:#4A4A4A;
    border:1px solid #4A4A4A;
}
.more-grey a{
    color:#4A4A4A;
}
.more-icon{
    width:23px;
    height:6px;
    display:inline-block;
    vertical-align:3px;
    margin-left:5px;
    background:url(/image/icon/more-grey.png) no-repeat;
}
.more-red-icon{
    background:url(/image/icon/more-red.png) no-repeat;   
}
.more-grey-icon{
    background:url(/image/icon/more-grey.png) no-repeat; 
}
.more:hover{
    color:#FF0000;
    border:1px solid #FF0000;
}
.more:hover a{
    color:#FF0000;
}
.more:hover .more-icon{
    background:url(/image/icon/more-red.png) no-repeat; 
}
.homepage {
    color: rgba(67, 67, 67, 1); 
}

.home-banner {
    background-color: antiquewhite;
    position: relative;
    width:100%;
    background-image:url(/image/index-banner.png);
    background-repeat: no-repeat;
    background-size:cover; 
    display: flex;
    align-items: center;
}
.banner-title > div{
    margin:20px 0;    
}

.banner-title{
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
}
.banner-logo{
    position: absolute;
    top: 0;
    left: 10px;  
}
.banner-logo span{
    margin:0 10px;      
}
.banner-logo span:first-child{
    /* border-right:1px solid #8c8989; */
    padding-right:20px;
    display:inline-block;
}
.banner-title h2{
    font-size:50px;
    color:#fff;
    letter-spacing: 10px;
}
.banner-btn{
   width:156px;
   height:44px;
   line-height:44px;
   text-align:center;
   font-size:14px;
   color:#fff;
   background:#FF0000; 
}
.banner-btn:hover{
    cursor: pointer;
}
.home-about {   
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:50px;
    padding-bottom:50px;
    background:#fff;
}
.title-tip{
    font-size:14px;
    color:#FF0000;
    margin:0;
}
.title {
    font-size: 34px;
    color:#434343;
}
.about-detail{
   margin-top:20px;
   line-height:25px;
   color:#434343;
   font-size:16px;  
   letter-spacing:1px; 
}
.about-detail p{
    line-height:30px;
}

.about-video{
    background:url(/image/about-bg.png);
    background-size:contain;
    background-repeat: no-repeat; 
    width: 100%;
    padding:20% 0;     
    background-position: center; 
    margin-top:40px; 
    display: flex;
    justify-content: center 
}
.about-btn{
    width:100%;
    margin-top:20px;
}
.about-btn ul{
    margin: 0 -5px;
    overflow:hidden;
}
.about-btn ul li{
    float:left;
    width:20%;   
    text-align:center;
    font-size:18px;
    color:#434343;
    height:75px;
    line-height:75px;
    padding-left: 5px;
    padding-right: 5px; 
    position: relative;
    margin-bottom: 20px
}
.about-btn ul li div{
    border:1px solid #BFBFBF; 
    cursor:pointer;
}
.about-btn ul li.active div{
    border:1px solid #FF0000;
    background:#FF0000;
    color:#fff;
}
.home-news {   
    background: rgba(242, 242, 242, 1);    
}
.home-news-switch>span{
    font-size:24px;   
    margin: 20px;
    padding: 10px 0;
    display: inline-block
}
.home-news-switch>span.active{
    color:#FF0000;
    border-bottom:2px solid #FF0000;
}
.home-news-switch>span:hover {
    cursor: pointer;
}
.home-news-content{
    display:flex;
    margin-top:20px;
}
.home-news-left{
    /* width:calc(50% - 40px); */
    width:50%;
    float:left;  
    text-align:left;
    padding: 10px 20px 10px;
    background:#fff;
    height:500px;
}
.home-news-left li{
    display:flex;
    color:#626262;
    border-bottom: 1px solid #efefef; 
    padding: 5px 0 30px;
    margin: 20px 0 20px;   
}
.home-news-left li:last-child{
    border:none;
    margin: 20px 0 0;
}
.news-left-img{
    width:100px;
    max-height:100px;   
    margin-right:20px;
    margin-top:6px;
    background-size: cover;
    max-height: inherit;
    background-repeat: no-repeat   
}
.news-left-content{
    flex:1 1;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
}
.news-left-content h4{
    font-size: 18px;
    color: #434343;
    font-weight: 600;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
}
.home-news-left li:hover .news-left-content h4{
    color:#FF0000;
}
.news-left-content h4.active{
    color: #FF0000;
}
.news-left-content p{   
   font-size:14px;
   line-height:20px;
   margin:5px 0;
   overflow:hidden;
   text-overflow: ellipsis;
   display:-webkit-box;
   -webkit-line-clamp:2;      
}
.news-left-content span{
    font-size:12px;  
}
.home-news-right{
    width:50%;
}
.ant-carousel .slick-slide {
    text-align: center;    
    background: #fff;
    overflow: hidden;
    height:500px;
}  
.ant-carousel .slick-slide h3 {
    color: #fff;
}
.carouse-img{
    background-size:cover;
    background-repeat: no-repeat;
    width:100%;
    height:500px;
    position: relative;    
}
.carouse-img-bottom{
    position: absolute;
    width:100%;    
    right:0;
    bottom:0;
    height:80px;
    z-index:99; 
    background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0)),to(rgba(0,0,0,1))); 
    background-image: -webkit-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1)); 
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
    font-size:16px;
    color:#fff;
    text-align:left;
    padding:5px 20px;
}
.carouse-img-bottom p{
    margin:0;
    line-height:25px;
}
.carouse-img-bottom span{
    font-size:14px;
}
.home-division-entrance {
    background:#fff;   
}
.home-division-entrance-box{    
    display:flex; 
    margin:20px -5px 0 -5px;
}
.home-division-entrance-left{
    width:25%;   
    padding:0 5px;
}
.home-division-entrance-right{
    width:75%;  
    padding:0 5px;
}
.home-division-entrance-right ul{
    margin:0 -5px;
}
.home-division-entrance-right li{
    width:16.666%;
    padding:0 5px;
    float:left;
    margin-bottom:10px;
    font-size:18px;
}
.home-division-entrance-right li a{
    border:1px solid #D2D2D2;
    display:block;
    border-radius:5px;
    height:64px;
    line-height:64px;
    color:#535353;
    
}
.home-division-entrance-right li:hover a,.home-division-entrance-right li.active a{
    background:#FF0000;
    color:#fff;
}
.home-division-entrance-right li.default a{
    background:#AAAAAA;
    color:#fff;
    cursor: default;
}
.home-division-entrance-left-box{  
    background:url(/image/star-bg.png) no-repeat;    
    background-size:cover;
    padding: 24% 0;
}
.home-division-area{
    font-size:50px;
    color:#fff;
    padding:10px 0;
}
.home-awards {    
    background: -webkit-linear-gradient(135deg, rgba(253, 119, 89, 1) 0%, rgba(234, 50, 63, 1) 100%);    
    background: linear-gradient(-45deg, rgba(253, 119, 89, 1) 0%, rgba(234, 50, 63, 1) 100%);
}
.home-awards .title-tip,.home-awards .title{
    color:#fff;
}
.home-awards-box ul{
    margin:0 -5px;
}
.home-awards-box li{
    width:20%;
    padding:0 5px;
    float:left;
    margin-bottom:10px;
}
.home-awards-box li div{
    width:100%;
    background:url(/image/prize.png) no-repeat;
    background-size:100%;
    padding-bottom: 106%;
    position: relative;
}
.home-awards-box li div p{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-75%);
            transform: translate(-50%,-75%);
    font-size: 14px;
    width: 100%;
    color:#FF0000;
}
.home-time {   
    background: rgba(255, 255, 255, 1);
}
.home-time-box{
    margin-top:20px;
    padding:50px 5px;
}
.home-time-box ul{
    width:100%;
}
.home-time-box ul li{
    width: 16.666%;
    display: inline-block;
    position: relative;
    font-size:16px;
    color:#434343;
    font-weight: 600;
    height: 100px;
    background:url(/image/icon/time-line.svg) repeat-x 0 51px;
}
.home-time-box ul li p{
    width:100%;
    padding:10px 0;
    position: absolute;
    left: -34%;
}
.home-time-box ul li p.home-times{    
    top: -25px;
}
.home-time-box ul li p.home-time-title{   
    bottom: -25px;
}
.home-time-box ul li span{
    width:60px;
    height:60px;
    background-size:100%;      
    display:inline-block;
    position: absolute; 
    left:0; 
    top: 20px;    
}
.phone-time-img{
    display:none;
}
.home-judges {   
    background: rgba(67, 67, 67, 1);
}
.home-judges .title-tip,.home-judges .title{
   color:#fff;
}
.home-judges-box{
    margin-top:40px;
}
.home-judges-box ul{
    margin:0 -5px;
    display:flex;
    justify-content:space-around
}
.home-judges-box ul li{
    width:20%;
    float:left;
    padding:0 5px;
    color:#fff;
}
.home-judges-box ul li img{
    max-width:120px;
}
.home-judges-box ul li h4{
    font-size:18px;
    color:#fff;
    padding:20px 0;
}
.home-judges-describe p{
    line-height:25px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.home-supports {  
    background: #f2f2f2;
}
.home-supports-box{
    margin:20px 0;
}
.home-supports-box ul{ 
    margin:0 -5px;
    /* display: flex;
    justify-content: center; */
}
.home-supports-box li{
    width:20%;
    float:left;
    padding:0 5px;
    margin-bottom:10px;
}
.home-supports-box li img{
    border:1px solid #e0dada;
}

.back{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum', "tnum", "tnum";
            font-feature-settings: 'tnum', "tnum", "tnum";
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
@media screen and (max-width: 1200px) {
    .container {
       width:100%;
    }
}
@media screen and (min-width: 768px) and (max-width:992px){   
    .title{
        font-size:24px;
    }
    .more{
        margin:20px auto 0;
    }
    .banner-title > div{
        margin:10px 0;
    }
    .banner-logo{
        position:inherit;
        margin: 10px 0 20px !important;
    }
    .home-news-content{
        flex-flow: column-reverse
    }       
    .home-news-left{
        width:100%; 
        background:none;
        padding:0; 
        height:auto;        
    }
    .home-news-right{
        width:100%;
    } 
    .home-news-left li{
        background:#fff;
        padding:0;
        margin: 10px 0;
    }     
    .news-left-img{
        margin-top:0;
    } 
    .news-left-content{
        flex:1 1;
        justify-content: space-around;
    }  
    .news-left-content p{
        display:none;
    } 
    .news-left-content h4{
        padding: 10px 10px 0 0;
        -webkit-line-clamp: 2;
    }  
    .home-division-entrance-box{
        flex-flow: column-reverse
    }
    .home-division-entrance-left{
        width:100%;
    }
    .home-division-entrance-right{
        width:100%;
    }
    .home-division-entrance-right li{
        width:33.333%;
        font-size:16px;
    }
    .home-awards-box li{
        width:33.333%;
    } 
    .home-judges-box ul li{
        width:50%;
        margin-bottom: 20px;        
    }
    .home-judges-describe p{
        letter-spacing: 0;
    }
    .home-supports-box li{
        width:25%;
    }
    .home-time-box ul{
        display:none;
    }
    .phone-time-img{
        display: block;
        margin:0 auto;
    }
    .home-awards-box li div p{
        font-size:16px;
    } 
    .ant-carousel .slick-slide{
        height:auto;
    }
    .home-division-entrance-left-box{
        padding:22px 0;
        background-size: 100%;
    }
    .carouse-img{
        height:auto;
        padding-bottom:67%;
    }
    .home-news-left li:last-child{
        margin:0;
    }
    .news-left-content span{
        padding-bottom:10px;
    }   
}
@media screen and (max-width: 767px) {    
    .title{
        font-size:24px;
    }
    .more{
        margin:20px auto 0;
    }
    .banner-title > div{
        margin:10px 0;
    }
    .banner-logo{
        position:inherit;
        margin: 10px 0 20px !important;
    }
    .home-news-content{
        flex-flow: column-reverse
    }       
    .home-news-left{
        width:100%; 
        background:none;
        padding:0; 
        height:auto;  
    }
    .home-news-right{
        width:100%;
    } 
    .home-news-left li{
        background:#fff;
        padding:0;
        margin: 10px 0;
    }     
    .news-left-img{
        margin-top:0;
    } 
    .news-left-content{
        flex:1 1;
        justify-content: space-around;
    }  
    .news-left-content p{
        display:none;
    } 
    .news-left-content h4{
        padding: 10px 10px 0 0;
        -webkit-line-clamp: 2;
    }  
    .home-division-entrance-box{
        flex-flow: column-reverse
    }
    .home-division-entrance-left{
        width:100%;
    }
    .home-division-entrance-right{
        width:100%;
    }
    .home-division-entrance-right li{
        width:33.333%;
        font-size:16px;
    }
    .home-awards-box li{
        width:50%;
    } 
    .home-judges-box ul li{
        width:50%;
        margin-bottom: 20px;        
    }
    .home-judges-describe p{
        letter-spacing: 0;
    }
    .home-supports-box li{
        width:50%;
    }     
    .home-time-box ul{
        display:none;
    }
    .phone-time-img{
        display: block;
        margin:0 auto;
    }
    .home-awards-box li div p{
        font-size:16px;
    } 
    .ant-carousel .slick-slide{
        height:auto;
    }
    .home-division-entrance-left-box{
        padding:22px 0;
        background-size: 100%;
    }     
    .carouse-img{
        height:auto;
        padding-bottom:67%;
    }
    .home-news-left li:last-child{
        margin:0;
    }
    .news-left-content span{
        padding-bottom:10px;
    }
    .news-left-content h4{
        font-size:16px;
    }
}
@media screen and (max-width: 540px) {
    .banner-title h2 {
       font-size:38px;
    }
}
.common-banner{
    position: relative;
    width:100%;
    background-image:url(/image/about-banner.png);
    background-repeat: no-repeat; 
    display: flex;
    align-items: center;
    /* padding-bottom: 8%;
    padding-top: 10%;  */
    height:340px;
}
.banner-title-line{
    width: 80px;
    height:4px;
    background-color: #fff;
}
.common-banner h2{
    font-size:34px;
    color:#fff; 
    letter-spacing: 2px;  
    padding:10px 0 0; 
}
.about-box{
    display:flex;      
    background: rgba(242, 242, 242, 1);      
}
.about-content{
    margin:0 -5px;
    min-height:400px;
}
.about-nav{    
    width: 240px;
    float: left;
    padding: 0 5px;
}
.about-nav ul li{
  background:#fff;  
  color:#4A4A4A;
  text-align:center;
  margin-bottom:10px; 
  height:60px;
  line-height:60px; 
  cursor:pointer;
  font-size:18px;
}
.about-nav ul li.active{
    background:#FE0000;
    color:#fff;
}
.about-right{    
    width: calc(100% - 240px);   
    padding: 0 5px;
    float: left;
}
.about-right-box{
    background: #fff;
    padding: 40px 20px; 
}
.about-right-box h2{
    font-size:24px;
    margin: 0;
}
.about-describe span{
    display:block;
}
.about-summary{
    padding:0 20px;
}
.about-summary h3{    
    font-weight:600;
}
.judges-name{
    padding:20px 0 15px;  
    color:#000;
}
.about-black{
    color:#000;    
    font-weight:600;
}
.about-summary ul li {
    border-bottom:1px solid #efefef;
    padding:40px 0 20px;
}
.about-summary ul li p{
    margin:0;
}
.about-summary ul li:last-child{
    border:none;
}
.about-right-box p{
    text-align: left;
    font-size:14px;
    color:#434343;
    line-height:25px;
    margin:10px 0;
}
.red-line{
    width:80px;
    height:4px;
    background: #FE0000;
    display:inline-block;
}
.ant-tabs {
    display:none;
}
.ant-tabs-bar{
    border:none;
}
.about-right-sort{
    max-height:700px;
    overflow-y: auto;
}
.about-right-sort::-webkit-scrollbar { display: none; }
@media screen and (max-width: 992px) {
    .common-banner{
        background-image:url(/image/phone/phone-about-banner.png);  
        background-size: 100%;
        padding-top: 16%;
        padding-bottom: 8%;
        height:auto;
    }
    .about-content{
        min-height:auto;
    }
    .about-nav,.about-right{
        width:100%;
    }
    .about-box{
        min-height:auto;
        padding: 20px 0 50px;        
    }
    .about-nav{
        display:none;
    }
    .ant-tabs {
        display:block;       
    }
    .ant-tabs-nav .ant-tabs-tab{
        font-size:16px;
    }
    .ant-tabs-nav .ant-tabs-tab-active{
        color:#FE0000;
    }
    .ant-tabs-nav .ant-tabs-tab:hover{
        color:#FE0000;
    }
    .ant-tabs-ink-bar{     
        background-color:#FE0000
    }
    .common-banner h2{
        font-size:24px;
    }
    .about-summary{
        padding:0;
    }
    
}

.news-banner{
    background-image:url(/image/news-banner.png);  
}

.News-right-box.home-news-left{
    background: none;
    width:100%;
    padding:0;
    margin-bottom:25px;
    height:auto;
}
.News-right-box.home-news-left li{
    background:#fff;
    padding:0;
    margin: 0px 0 10px;
}
.News-right-box.home-news-left .news-left-img{
    margin:0;
    background-size: cover;
    max-height: inherit;
    background-repeat: no-repeat;
    width:250px;
    background-position: inherit;
}
.News-right-box.home-news-left .news-left-content p {
    margin:10px 0;
}
.News-right-box .news-left-content{
    padding:25px 20px;
    flex:1 1;
}
.News-right-box.home-news-left li:hover{
    box-shadow: 0 0 7px rgba(0,0,0,.2);
}
.news-footer{
    display:flex;
    justify-content: space-between;
}
@media screen and (max-width: 992px) {
    .news-banner{
        background-image:url(/image/phone/phone-news-banner.png);
    }
    .about-nav,.about-right{
        width:100%;
    } 
    .News-right-box .news-left-content{
        padding:15px 20px;
    } 
    .news-footer{
        padding-top:10px;
    }  
}
@media screen and (max-width: 767px) {
    .News-right-box.home-news-left .news-left-img{
        width:100px;
    }    
}
.news-right-details{
    background:#fff;
    padding: 20px;
}
.news-right-details h2{
    font-size:24px;
    font-weight:600;
    color:#4a4a4a;
    letter-spacing: 1px;
}
.news-details-tip span{
    font-size:16px;
    padding: 0 20px 0;
}
.news-details-tip span:first-child{
    border-right:1px solid #4a4a4a;
}
.about-nav ul li a{
    color:#fff;
}
.news-details-p{
    padding:20px 0;
}
.news-details-p p{
    text-align:left;
    line-height:25px;
    color:#4a4a4a;
}
.news-details-p img{
    width:100% !important;
}
.updates-banner{
    background-image:url(/image/updates-banner.png); 
}
.updates-content{
    margin:0 -5px;
}
.updates-content ul li{
    width:16.666%;
    float: left;
    padding:0 5px;
    font-size:16px;
    text-align:center; 
    color:#4A4A4A; 
    height:60px;
    line-height:60px;
    margin:5px 0;  
}
.updates-content ul li div{
    background:#fff;
    border-radius:5px;
    cursor: pointer;
}
.updates-content ul li.active div,.updates-content ul li:hover div{
    background:#FE0000;
    color:#fff;
}
.updates-content ul li.default div{
    background: #AAAAAA;
    color: #fff;
    cursor: default;
}
@media screen and (max-width: 992px) {
    .updates-banner{
        background-image:url(/image/phone/phone-updates-banner.png);        
    } 
    .updates-content ul li{
        width:50%;
    }  
    
}
em{
    font-style: normal;
}
.works-banner{
    background-image:url(/image/works-banner.png); 
    background-position: center;
    height:340px;
    padding:0;
}
.updates-content{
    margin:0 -5px;
}
.works-box{
    display: flex;
    flex-flow: column;
}
.works-nav-box{
    background:#fff;
    position: relative;
    border-bottom:2px solid #DEDBDB;
}
.works-content{
    background: rgba(242, 242, 242, 1);
    min-height:400px;
}
.works-nav{
    padding-top:20px;  
}
.works-nav ul {
    display: flex;
    margin: 0 auto;
    width: 50%;
    justify-content: space-between;  
}
.works-nav ul li{
    float:left; 
    line-height:45px;
    font-size:16px; 
    position: relative;  
}
.works-nav ul li i{
    background:url('/image/icon/down-arrow.svg') no-repeat;
    background-size:100%;
    width:12px;
    height:7px;
    display:inline-block;
    margin-left:5px;
    -webkit-transition: -webkit-transform .3s linear;
    transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear, -webkit-transform .3s linear;
    -webkit-transition: transform .3s linear;
    
}
.works-nav ul li.active i{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    background:url('/image/icon/down-arrow.svg') no-repeat;
}

.works-nav ul li.active div{
    border-bottom:2px solid #FE0000;
}
.works-list{
    margin:0 -10px;
    overflow: hidden;
}
.works-list li{
    width:25%;
    *width:168px;
    padding-left:10px;
    padding-right:10px;
    float:left;
    position:relative;
    margin-bottom:20px;
    cursor:pointer;
}
.works-list li:last-child{
    margin-right:0;
}
.works-list .works-list-item{
    overflow:hidden;
    background: #f5f5f5
}
.works-pic{
    *width:168px;
    margin:0 auto;
    text-align:center;
    height:130px;
    overflow:hidden;
    position:relative;
    border-radius: 4px 4px 0 0;
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
}
.works-pic img{
    max-width:100%;
    height:auto;    
}
.works-list-summary{
    overflow:hidden;
    padding-bottom:5px;
    text-align:left; 
    background:#fff;  
}
.works-list-summary h4{
    font-size:16px;
    color:#4a4a4a;
    font-weight:600;
    padding:10px 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.works-list-summary p{
    color:#9B9B9B;
    line-height:25px;
    padding:0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.works-list .works-list-item:hover{
    box-shadow:0 0 7px rgba(0,0,0,.3);
}
.works-opertion{   
    padding:10px;
}
.works-opertion span{
    display:inline-block;
    color:#9B9B9B;
    margin-right:10px;
    cursor: default;
}
.works-opertion span img{
    vertical-align: baseline;
    margin-right:5px;
}
.works-bottom{
    border-top:1px solid #F2F2F2;
    display:flex;
    padding:10px;
    justify-content: space-between;
}
.works-name{
    color: #4a4a4a;
}
.works-time{
    color:#9B9B9B;    
    text-align: right;
}
.all-menu{
    position: absolute;
    top:101%;
    left:0;
    width:100%;
    z-index:99;
    background:#fff;
    box-shadow:1px 1px 3px rgba(0,0,0,.3);     
}
.all-menu.show{
    -webkit-transition: .5s ease-in-out;
        transition: .5s ease-in-out; 
}
.all-menu ul{
    display:flex;
    padding:20px 0;
    flex-flow: wrap;
    justify-content: space-around;
}
.all-menu ul li{
    float:left;    
    font-size:18px;
    color:#4A4A4A;
    text-align: center;
    cursor: pointer;
}
.all-menu ul li.active,.all-menu ul li:hover{
    color:#FE0000;    
}
.all-menu.area-menu ul li{
    width:16.666%;    
    flex: none;
    margin:10px 0;
}

/* 分页 */
.pagebox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:14px;
    color:#4a4a4a;
    width:100%;
    margin-top:15px;
}
.pageleft span,.pageright span{
    width:80px;
    height:45px;
    line-height:45px;
    display:inline-block;  
    background:#fff;    
    font-size:14px; 
    cursor:pointer; 
}
.pageleft span{
    margin-right:10px;
}
.pageright span{
    margin-left:10px;
}
/* .pagebox span i{
    width:9px;
    height:12px;
    display:inline-block;
    background-repeat:no-repeat;
    background-position: center;    
}
.pageprev i{
    background-image:url(/image/icon/prev-icon.svg);   
    margin-right:3px;
}
.pagenext i{
    background-image:url(/image/icon/next-icon.svg);
    margin-left:3px;
} */
.pageleft span:hover,.pageright span:hover,.pageleft span.active,.pageright span.active{
    background:#FE0000;
    color:#fff;
}
.pageNumInput{   
    width:60px; 
    height:45x;
    line-height:45px;
    text-align:center;
    border:none;
    background:#fff;
    margin-left:3px;
    margin-right:3px;
    vertical-align:middle;
}

@media screen and (max-width: 992px) {
    .works-banner{
        background-image:url(/image/phone/phone-works-banner.png);    
        height:auto; 
        padding-top: 16%;
        padding-bottom: 8%;   
    } 
    .updates-content ul li{
        width:50%;
    }  
    .works-list li{
        width:50%;
    }    
    .all-menu ul li{
        font-size:14px;
    } 
    .works-content{
        padding:20px 0 50px;
        min-height:auto;
    }   
}
@media screen and (min-width: 768px) and (max-width:992px){
    .works-list li{
        width:33.333%;
    }
}
@media screen and (max-width:767px){
    .works-nav ul li{
        font-size:14px;
    }
    .all-menu ul{
        justify-content: inherit;
    }    
    .all-menu ul{
        padding:10px 0;
    }
    .all-menu ul li{
        width:33.333%;
        margin:10px 0;
    }
    .all-menu.area-menu ul li{
        width:33.333%;
        margin:10px 0;
    }
    .all-menu.area-menu ul{
        overflow-y: auto;
        overflow-x: hidden;
        margin:20px 0;
        max-height: 160px;
    }
    .pageleft span.pagefirst,.pageright span.pagelast{
       display:none;
    }
    .pagemid{
        display:none;
    }
    
}
@media screen and (max-width:400px){
    .works-nav ul{
        width: 80%;
    } 
    .works-nav ul li{
        font-size:14px;
    }
}
@media screen and (max-width:320px){
    .all-menu ul li{
       width:50%;  
    }
}

.works-details-content{
    background:#fff;
    padding:20px 20px;
}
.works-details-top{
    display:flex;
    justify-content:space-between;
    align-items: center; 
    padding: 20px 0;
}
.works-details-top-left{
    width:70%;
    float:left;  
    padding:0 10px; 
    border-right:1px solid #DEDBDB;
}
.works-details-top-left h2{
    text-align:left;
    font-weight:600;
    color:#4A4A4A;
    margin:0;
}
.works-details-top-right{
    width:30%;   
    padding:0 10px 0 20px;
    display: flex;
    align-items: center;
}
.works-left-tip{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.works-top-sorts span{
   margin-right:10px;
   color:#6D6C6D;
}
.works-top-sorts span:first-child{
    border-right:1px solid #DEDBDB;
    padding-right: 10px; 
}
.works-avatar{
    width:74px;
    height:74px;
    border-radius: 100%;
    overflow: hidden;
    background-size: 100%;
}
.works-avatar-describe{
    text-align:left;
    margin-left:10px;
}
.works-details-summary{      
    text-align: left;
}
.works-details-summary > div{
    padding:20px 0; 
    border-top:1px solid #DEDBDB;
}
.works-details-summary p{
    line-height:25px;
    color:#4a4a4a;
    margin-bottom:10px;
}
.go-back{
    padding:20px 5px 0;
    text-align:left;
}
.go-back a{
    color:#4a4a4a;
}
.go-back img{
    margin-right:8px;
    vertical-align: unset;
}
@media screen and (max-width:992px){
    .works-details-top{
        flex-flow: wrap-reverse;
    }
    .works-details-top-left{
        width:100%;
        margin-top:20px;
        border:none;
    }
    .works-details-top-right{
        width:100%;
        justify-content: center;
        display: flex;
        flex-flow: column;
    }
    .works-avatar-describe{
        margin:0;
        text-align:center;
    }
    .works-left-tip{
        flex-flow: wrap-reverse;
        justify-content: center
    }
    .works-details-top-left h2{
        text-align: center;
    }
    .go-back{
        margin-bottom: 20px;
    }
    
}
.awards-banner{
    background-image: url(/image/awards-banner.png);
    background-position: center;
}
.awards .about-right-box{
    overflow:hidden
}
.awards-only{ 
    width:100%;  
    padding:0 5px;
}
.awards-pic{
    overflow:hidden;
    border-bottom:1px solid #DEDBDB;
    margin-bottom: 20px;
}
.awards-pic h3{
    margin:0;
}
.awards-pic-cover{
    padding:20px 0;
    position: relative;
}
.totalnum{
    position: absolute;
    bottom:45px;
    right:20px;
    padding:4px 10px;
    background:rgba(5, 5, 5, 0.8);
    color:#fff;
    font-size:14px;
}
.awards-pic-left{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-pic-right{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-two .awards-pic-left{
    width:50%;
    float:left;
    padding:0 5px;   
}
.awards-two .awards-pic-right{
    width:50%;
    float:left;
    padding:0 5px;
}
.awards-pic .works-details-top-left{
    width:100%;
    padding:0 5px;
    border:none;
    margin: 20px 0;
}
.works-details-top-left h2{
    font-weight:500;
}
.awards-pic .works-opertion span{
    margin-right:0;
    margin-left:10px;
}
.awards-pic .works-top-sorts span:first-child{
    font-size:18px;
    border:none;
    padding-right:0;
}
.awards-pic .red-line{
    width:40px;
}
@media screen and (max-width: 992px) {
    .awards-banner{
        background-image: url(/image/phone/phone-awards-banner.png);
    }
    .awards-pic-cover{
        padding:20px 0 0;
    }
    .totalnum{
        bottom:25px;
    }
}

.awards-details .about-right{
   width:100%;
}
.awards-only ul li{
    margin:5px 0;
    
}
.awards-pic-describe p{
   line-height:25px;
   font-size:14px;
   margin: 10px 0;
   color: #4a4a4a;
}
.awards-details .awards-pic{
    border:none;
}
.awards-details .awards-pic .works-details-top-left{
    margin:20px 0 10px;
}
.noData{
    padding: 200px 0;
    margin: 0 auto;
    display: table;
    text-align: center;
    max-width:240px;  
    /* position: fixed;
    top: 50%;
    left: 50%;
    height: auto;
    z-index: 98;
    width: auto;
    min-width: 240px;   
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%); */    
}
.noData p{
    font-sizE: 18px;
    color: #4a4a4a;
    margin-top: 28px;
    letter-spacing: 1px;    
}
.review-box .about-box{
    min-height:700px;
}
@media screen and (max-width: 992px) {
    .noData{
        max-width:160px;
        padding:100px 0;
    }
    .noData p{
        font-size:16px;
    }
    .review-box .about-box{
        min-height:auto;
    }
}
.contact-banner{
    background-image:url(/image/contact-banner.png);
    background-position:center;
}
.contact-content{
    display:flex;
}
.contact-left{
    width:50%;
    float:left;
    padding:0 0 0 10px;
  
    background-image: -webkit-radial-gradient(0 25%,#FD7759,#E60012);
}
.contact-right{
    width:50%;
    float:left;
    padding:0 10px 0 0;   
}
.contact-left-text{
    padding:30px 50px;
    text-align:left;
    color:#fff;
    font-size:14px;
}
.contact-left-text h3{
    font-size:24px;
    color:#fff;
    letter-spacing: 1px;
}
.contact-left-text ul li{
    display:flex;
    align-items: center;
    padding:5px 0 10px;
}
.contact-left-text ul li p{
    flex:1 1;
}
.contact-icon{
    width:90px;
}
.contact-icon span{
    width:20px;
    display: inline-block;
    margin-right:10px;
}
.map{
    background:url(/image/map.png) no-repeat;
    background-size:100%;
    background-position:center;
    padding-bottom: 63%;    
}
.BMap_Marker > div img{
    width:39px;
    height:25px;
    max-width:inherit;
}
.about-summary.contact-summary p{
    margin: 20px 0;
}
@media screen and (max-width: 992px) {
    .contact-banner{
        background-image:url(/image/phone/phone-contact-banner.png);
    }
    .contact-left,.contact-right{
        width:100%;
    }
    .contact-content{
        flex-flow: column;
    }  
    .contact-right{
        padding:0;
    } 
    .mapbox{
        height:300px !important;
    }
    .about-summary.contact-summary p{
        margin: 10px 0;
    }
}

@media screen and (max-width: 540px) {
    .contact-left-text{
        padding:20px;
    }
}
