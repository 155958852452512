
.works-details-content{
    background:#fff;
    padding:20px 20px;
}
.works-details-top{
    display:flex;
    justify-content:space-between;
    align-items: center; 
    padding: 20px 0;
}
.works-details-top-left{
    width:70%;
    float:left;  
    padding:0 10px; 
    border-right:1px solid #DEDBDB;
}
.works-details-top-left h2{
    text-align:left;
    font-weight:600;
    color:#4A4A4A;
    margin:0;
}
.works-details-top-right{
    width:30%;   
    padding:0 10px 0 20px;
    display: flex;
    align-items: center;
}
.works-left-tip{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.works-top-sorts span{
   margin-right:10px;
   color:#6D6C6D;
}
.works-top-sorts span:first-child{
    border-right:1px solid #DEDBDB;
    padding-right: 10px; 
}
.works-avatar{
    width:74px;
    height:74px;
    border-radius: 100%;
    overflow: hidden;
    background-size: 100%;
}
.works-avatar-describe{
    text-align:left;
    margin-left:10px;
}
.works-details-summary{      
    text-align: left;
}
.works-details-summary > div{
    padding:20px 0; 
    border-top:1px solid #DEDBDB;
}
.works-details-summary p{
    line-height:25px;
    color:#4a4a4a;
    margin-bottom:10px;
}
.go-back{
    padding:20px 5px 0;
    text-align:left;
}
.go-back a{
    color:#4a4a4a;
}
.go-back img{
    margin-right:8px;
    vertical-align: unset;
}
@media screen and (max-width:992px){
    .works-details-top{
        flex-flow: wrap-reverse;
    }
    .works-details-top-left{
        width:100%;
        margin-top:20px;
        border:none;
    }
    .works-details-top-right{
        width:100%;
        justify-content: center;
        display: flex;
        flex-flow: column;
    }
    .works-avatar-describe{
        margin:0;
        text-align:center;
    }
    .works-left-tip{
        flex-flow: wrap-reverse;
        justify-content: center
    }
    .works-details-top-left h2{
        text-align: center;
    }
    .go-back{
        margin-bottom: 20px;
    }
    
}