.updates-banner{
    background-image:url(/image/updates-banner.png); 
}
.updates-content{
    margin:0 -5px;
}
.updates-content ul li{
    width:16.666%;
    float: left;
    padding:0 5px;
    font-size:16px;
    text-align:center; 
    color:#4A4A4A; 
    height:60px;
    line-height:60px;
    margin:5px 0;  
}
.updates-content ul li div{
    background:#fff;
    border-radius:5px;
    cursor: pointer;
}
.updates-content ul li.active div,.updates-content ul li:hover div{
    background:#FE0000;
    color:#fff;
}
.updates-content ul li.default div{
    background: #AAAAAA;
    color: #fff;
    cursor: default;
}
@media screen and (max-width: 992px) {
    .updates-banner{
        background-image:url(/image/phone/phone-updates-banner.png);        
    } 
    .updates-content ul li{
        width:50%;
    }  
    
}