.contact-banner{
    background-image:url(/image/contact-banner.png);
    background-position:center;
}
.contact-content{
    display:flex;
}
.contact-left{
    width:50%;
    float:left;
    padding:0 0 0 10px;
  
    background-image: -webkit-radial-gradient(0 25%,#FD7759,#E60012);
}
.contact-right{
    width:50%;
    float:left;
    padding:0 10px 0 0;   
}
.contact-left-text{
    padding:30px 50px;
    text-align:left;
    color:#fff;
    font-size:14px;
}
.contact-left-text h3{
    font-size:24px;
    color:#fff;
    letter-spacing: 1px;
}
.contact-left-text ul li{
    display:flex;
    align-items: center;
    padding:5px 0 10px;
}
.contact-left-text ul li p{
    flex:1;
}
.contact-icon{
    width:90px;
}
.contact-icon span{
    width:20px;
    display: inline-block;
    margin-right:10px;
}
.map{
    background:url(/image/map.png) no-repeat;
    background-size:100%;
    background-position:center;
    padding-bottom: 63%;    
}
.BMap_Marker > div img{
    width:39px;
    height:25px;
    max-width:inherit;
}
.about-summary.contact-summary p{
    margin: 20px 0;
}
@media screen and (max-width: 992px) {
    .contact-banner{
        background-image:url(/image/phone/phone-contact-banner.png);
    }
    .contact-left,.contact-right{
        width:100%;
    }
    .contact-content{
        flex-flow: column;
    }  
    .contact-right{
        padding:0;
    } 
    .mapbox{
        height:300px !important;
    }
    .about-summary.contact-summary p{
        margin: 10px 0;
    }
}

@media screen and (max-width: 540px) {
    .contact-left-text{
        padding:20px;
    }
}